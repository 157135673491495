import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.css']
})
export class MainComponent implements OnInit {
  initLang = 'en';
  constructor(
    public translate: TranslateService
  )  {
    this.translate.addLangs(['en', 'ko']);
    this.translate.setDefaultLang(this.translate.getBrowserLang());
   }

  ngOnInit() {
    if(sessionStorage.lang){
      this.initLang = sessionStorage.lang;
      this.translate.use(this.initLang);
    //   $(".lang_button div").removeClass("active")
    // $("."+this.initLang).addClass("active");
    } else{
    if (!this.translate.currentLang) 
    {
      
      if(this.translate.getBrowserLang()=='ko'||this.translate.getBrowserLang()=='en')
      { 
        this.translate.use(this.translate.getBrowserLang());
    this.switchLanguage(this.translate.currentLang);

      }
      else{
        this.translate.use('en');
    this.switchLanguage(this.translate.currentLang);

      }
    }
   this.initLang = this.translate.currentLang;
  }
  
    // this.loadScript('../../assets/js/jquery.min.js');
    // this.loadScript('../../assets/js/bootstrap.min.js');
    // this.loadScript('../../assets/js/pace.min.js');
    // this.loadScript('../../assets/js/debouncer.min.js');
    // this.loadScript('../../assets/js/jquery.easing.min.js');
    // this.loadScript('../../assets/js/jquery.inview.min.js');
    // this.loadScript('../../assets/js/jquery.matchHeight.js');
    // this.loadScript('../../assets/js/isotope.pkgd.min.js');
    // this.loadScript('../../assets/js/imagesloaded.pkgd.min.js');
    // this.loadScript('../../assets/js/jquery.flexslider.min.js');
    // this.loadScript('../../assets/js/jquery.countTo.js');
    // this.loadScript('../../assets/js/jquery.magnific-popup.min.js');
    // this.loadScript('../../assets/js/jquery.validate.min.js');
    // this.loadScript('../../assets/js/jquery.mb.YTPlayer.min.js');
    // this.loadScript('../../assets/js/main.js');
   
  }
  switchLanguage(language: string) {
    this.translate.use(language);
    sessionStorage.lang=language;
    // $(".lang_button div").removeClass("active")
    // $("."+language).addClass("active");
  }
  loadScript(url) {
    let node = document.createElement('script');
    node.src = url;
    document.getElementsByClassName('js')[0].appendChild(node);
  }
}
